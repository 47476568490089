import Vue from "vue";
import Vuex from "vuex";

Vue.use(Vuex);

const store = new Vuex.Store({
  state: {
    categories: [],
    search_query: {sort: 'new', keyword: ''}
  },
  getters: {
    getCategoryIdByName: (state) => (name) => {
      return state.categories.find(category => category.name === name).id
    },
    getCategoryById: (state) => (id) => {
      return state.categories.find(category => category.id === id).name
    },
    getPrevSearchQuery: (state) => () => {
      console.log("call getPrevSearchQuery")
      return state.search_query
    }
  },
  mutations: {
    setCategories (state, payload) {
      state.categories = payload
    },
    setSearchQuery(state, payload) {
      state.search_query = payload
    }
  },
  actions: {
    updateCategories ({ commit }, categories) {
      commit('setCategories', categories)
    },
    updateSearchQuery( {commit }, payload) {
      commit('setSearchQuery', payload)
    }
  }
});

export default store;