import axios from 'axios'
import { getInstance } from "@/auth";
import MobileDetect from 'mobile-detect';
const md = new MobileDetect(window.navigator.userAgent);

let extra_params = {}
if (md.mobile()) {
  extra_params.m = 'on'
}

const authAxios = axios.create()
authAxios.interceptors.request.use(async req => {
  const authService = getInstance();
  req.headers['Authorization'] = `Bearer ${await authService.getTokenSilently()}`;

  req.params = {
    ...req.params,
    ...extra_params
  }
  return req;
});

export default authAxios