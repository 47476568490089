import Vue from "vue";
import VueRouter from "vue-router";
import { authGuard } from "@/auth";
import store from "@/store/index.js";
import axios from "axios";

Vue.use(VueRouter);

const routes = [
  {
    path: "/",
    component: () => import("@/views/Base.vue"),
    beforeEnter: (to, from, next) => {
      return authGuard(to, from, next, "login:portal");
    },
    children: [
      {
        path: "",
        name: "Home",
        component: () => import("@/views/Home.vue"),
      },
      {
        path: "advanced_dojo",
        name: "AdvancedDojo",
        component: () => import("@/views/AdvancedDojo.vue"),
      },
      {
        path: "basics",
        name: "Basics",
        component: () => import("@/views/Basics.vue"),
      },
      {
        path: "basics/single/:id",
        name: "BasicsSingle",
        props: true,
        component: () => import("@/views/BasicsSingle.vue"),
      },
      {
        path: "batch",
        name: "Batch",
        component: () => import("@/views/Batch.vue"),
      },
      {
        path: "ctf",
        name: "Ctf",
        component: () => import("@/views/Ctf.vue"),
      },
      {
        path: "dojo",
        name: "Dojo",
        component: () => import("@/views/Dojo.vue"),
      },
      {
        path: "review/ex-learning/",
        name: "ReviewExLearning",
        component: () => import("@/views/ReviewExLearning.vue"),
        props: (route) => ({ qs: route.query }),
      },
      {
        path: "review/ex-learning/:id/",
        name: "ReviewExLearningDetail",
        props: true,
        component: () => import("@/views/ReviewExLearningDetail.vue"),
      },

      {
        path: "face_up",
        name: "FaceUp",
        component: () => import("@/views/FaceUp.vue"),
      },
      {
        path: "face_up/single/:id",
        name: "FaceUpSingle",
        props: true,
        component: () => import("@/views/FaceUpSingle.vue"),
      },
      {
        path: "info",
        name: "Info",
        component: () => import("@/views/Info.vue"),
      },
      {
        path: "info/single/:id",
        name: "InfoSingle",
        props: true,
        component: () => import("@/views/InfoSingle.vue"),
      },
      {
        path: "mypage",
        name: "Mypage",
        component: () => import("@/views/Mypage.vue"),
      },
      {
        path: "new",
        name: "New",
        component: () => import("@/views/New.vue"),
      },
      {
        path: "search",
        name: "Search",
        component: () => import("@/views/Search.vue"),
      },
      {
        path: "tool",
        name: "Tool",
        component: () => import("@/views/Tool.vue"),
      },
      {
        path: "tool/single/:id",
        name: "ToolSingle",
        props: true,
        component: () => import("@/views/ToolSingle.vue"),
      },
      {
        path: "trend",
        name: "Trend",
        component: () => import("@/views/Trend.vue"),
      },
      {
        path: "trend/single/:id",
        name: "TrendSingle",
        props: true,
        component: () => import("@/views/TrendSingle.vue"),
      },
      {
        path: "message/:id",
        name: "Message",
        props: true,
        component: () => import("@/views/Message.vue"),
      },
      {
        path: "message/admin/:id",
        name: "MessageAdmin",
        props: true,
        component: () => import("@/views/MessageAdmin.vue"),
      },
    ],
  },
  {
    path: "/manage",
    component: () => import("@/views/Admin.vue"),
    beforeEnter: (to, from, next) => {
      return authGuard(to, from, next, "admin:portal");
    },
    children: [
      {
        path: "",
        name: "Admin",
        component: () => import("@/views/admin/Top.vue"),
      },
      {
        path: "contents",
        name: "Contents",
        component: () => import("@/views/admin/Contents.vue"),
      },
      {
        path: "announcements",
        name: "Announcements",
        component: () => import("@/views/admin/Announcements.vue"),
      },
      {
        path: "contacts",
        name: "Contacts",
        component: () => import("@/views/admin/Contacts.vue"),
      },
      {
        path: "ex-review",
        name: "ExReview",
        component: () => import("@/views/admin/ExReview.vue"),
      },
    ],
  },
  {
    path: "/unauthorized",
    name: "Unauthorized",
    component: () => import("@/views/Unauthorized.vue"),
  },
];

const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  routes,
});

router.beforeEach((to, from, next) => {
  window.scrollTo(0, 0);

  // カテゴリ取得
  if (store.state.categories.length) {
    next();
  } else {
    axios
      .get("/api/categories")
      .then((response) => {
        store.dispatch("updateCategories", response.data);
      })
      .finally(() => {
        next();
      });
    //const instance = getInstance();
    //instance.$watch("loading", loading => {
    //  if (loading === false && instance.isAuthenticated) {
    //    instance
    //      .getTokenSilently()
    //      .then(accessToken => {
    //        axios.get('/api/categories', {
    //          headers: {
    //            Authorization: `Bearer ${accessToken}`
    //          },
    //        })
    //          .then(response => {
    //            store.dispatch('updateCategories', response.data)
    //          })
    //          .finally(() => {
    //            next()
    //          })
    //      })
    //  }
    //})
  }
});

export default router;
