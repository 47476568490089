<template>
  <div id="app" :class="responsiveClass()">
    <router-view></router-view>
  </div>
</template>

<script>
import MobileDetect from 'mobile-detect';
const md = new MobileDetect(window.navigator.userAgent);

export default {
  name: "App",
  methods: {
    responsiveClass() {
      if (md.phone()) {
        // スマホ表示
        return {'-mobile -menu-mobile': true}
      } else if (md.mobile()) {
        // タブレット表示
        return {'-menu-mobile': true}
      } else {
        if ('ontouchend' in document) {
          // タブレット表示(iOS13以降のiPad対応)
          return {'-menu-mobile': true}
        } else {
          // PC表示
          return {}
        }
      }
    }
  }
};
</script>