import Vue from 'vue'
import moment from "moment";

import App from './App.vue'
import router from './router'
import store from './store'
import authAxios from './plugins/authAxios'
import vuetify from './plugins/vuetify'

Vue.config.productionTip = false

// Auth0
import { Auth0Plugin } from "./auth"
import { domain, clientId, audience, scope } from "../auth.config.json"
Vue.use(Auth0Plugin, {
  domain,
  clientId,
  audience,
  scope,
  onRedirectCallback: appState => {
    router.push(
      appState && appState.targetUrl
        ? appState.targetUrl
        : window.location.pathname
    );
  }
});

// Tippy
import VueTippy from 'vue-tippy'
Vue.use(VueTippy, {
  arrow: true,
});

// Smooth Scroll
import VueSmoothScroll from 'vue2-smooth-scroll'
Vue.use(VueSmoothScroll, {
  updateHistory: false,
})

// Swiper
import { Swiper as SwiperClass, Navigation, Pagination, Autoplay } from 'swiper'
import getAwesomeSwiper from 'vue-awesome-swiper/dist/exporter'
import 'swiper/swiper-bundle.css'
SwiperClass.use([Navigation, Pagination, Autoplay])
Vue.use(getAwesomeSwiper(SwiperClass), {
  loop: true,
  autoplay: {
    delay: 3000,
    stopOnLastSlide: false,
    disableOnInteraction: false,
    reverseDirection: false
  },
  slidesPerView: 2,
  navigation: {
    nextEl: ".c_rcmnd_slide_arrow_next",
    prevEl: ".c_rcmnd_slide_arrow_prev"
  },
  pagination: {
    el: ".c_rcmnd_pager",
    type: "bullets",
    clickable: true
  }
})

// Modal
import VModal from 'vue-js-modal'
Vue.use(VModal, {
    draggable: true,
})

// Paginate
import Paginate from 'vuejs-paginate'
Vue.component('paginate', Paginate)

// filters
Vue.filter('formatDateDot', function (value) {
  if (value) {
    return moment(value).format('YYYY.MM.DD')
  }
})
Vue.filter('formatDateHyphen', function (value) {
  if (value) {
    return moment(value).format('YYYY-MM-DD')
  }
})

// axios
Vue.prototype.$authAxios = authAxios

new Vue({
  store,
  router,
  vuetify,
  render: h => h(App),
}).$mount('#app')
